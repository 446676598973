import ActivityFilter from '../activity-filter/activity-filter.js';
import { novaLocalize } from '../../../../shared/l10n/localize.js';

export default class CareerStream {
  constructor(base = {}) {
    this.sourceType = base.sourceType || 'lots'; // could also be 'jobs'
    this.careerData = base.careerData || undefined;
    this.activities = base.activities || undefined;
    this.careerSkillTypes = base.careerSkillTypes || 'specialized'; // could also be 'both' or 'common'
    this.results = base.results || []; // results of activity search
    this.filters = base.filters
      ? new ActivityFilter({ ...base.filters, [this.sourceType]: [this.careerData.id] })
      : new ActivityFilter({ [this.sourceType]: [this.careerData.id] });
    this.subtitle = base.subtitle || undefined;
  }

  get skillText() {
    if (this.subtitle) {
      return this.subtitle;
    }

    if (!this.activities) {
      return undefined;
    }

    const skillMap = {};
    const LIMIT = 3;
    let index = 0;
    const BreakError = {};

    // Get a list of unique (zero-indexed) primary skills from each activity.
    // If we can't find enough to meet the LIMIT requirement, check the next index, stop after LIMIT number of tries.
    while (Object.values(skillMap).length < LIMIT && index < LIMIT) {
      try {
        this.activities.forEach(activity => {
          // at this point activity.skills has already filtered out omitted skills
          // and activity.skills are sorted by significance
          if (activity.skills?.length > index) {
            const activitySkill = activity.skills[index]; // most significant skill
            const { id: skillId } = activitySkill;
            if (skillMap[skillId] !== undefined) return;

            if (this.careerSkillTypes === 'both' || this.careerSkillTypes === 'specialized') {
              const specializedSkills = this.careerData.skills.specialized;
              if (specializedSkills[skillId]) {
                skillMap[skillId] = specializedSkills[skillId];
              }
            }

            if (this.careerSkillTypes === 'both' || this.careerSkillTypes === 'common') {
              const commonSkills = this.careerData.skills.common;
              if (commonSkills[skillId]) {
                skillMap[skillId] = commonSkills[skillId];
              }
            }
          }

          // Stop the forEach loop if we have met the LIMIT requirement
          if (Object.values(skillMap).length >= LIMIT) throw BreakError;
        });
      } catch (error) {
        if (error !== BreakError) throw error;
      }

      index++;
    }

    const skillArray = Object.values(skillMap);
    skillArray.length = Math.min(skillArray.length, LIMIT);
    const listOfSkills = skillArray.map(s => s.name).join(', ');
    return novaLocalize('career-stream.topSkills', { listOfSkills });
  }

  get streamProperties() {
    return {
      type: 'career',
      careerData: this.careerData,
      displayName: novaLocalize('career-stream.headingIntro', { careerName: this.careerData.name }),
      id: this.careerData.id,
      path: `career/${this.careerData.id}`,
      filters: this.filters,
      subtitle: this.skillText,
      results: this.results,
    };
  }

}

import '@brightspace-ui/core/components/tabs/tabs.js';
import '@brightspace-ui/core/components/tabs/tab-panel.js';

import './edit-permissions/edit-permissions.js';
import './edit-roles/edit-roles.js';

import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';

import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';

class AdminPermissions extends LocalizeNova(SkeletonMixin(LitElement)) {
  static get properties() {
    return {
      // Add properties here
    };
  }

  static get styles() {
    return css`
      d2l-tabs {
        max-width: 1170px;
        width: 90vw;
      }
    `;
  }

  render() {
    return html`
      <d2l-tabs>
        <d2l-tab-panel text="${this.localize('view-admin.permissions.userPermissions')}">
          <edit-permissions></edit-permissions>
        </d2l-tab-panel>
        <d2l-tab-panel text="${this.localize('view-admin.permissions.roles')}">
          <edit-roles></edit-roles>
        </d2l-tab-panel>
        <d2l-tab-panel text="${this.localize('view-admin.permissions.audit')}">
          <p>Hey</p>
        </d2l-tab-panel>
      </d2l-tabs>
    `;
  }
}
window.customElements.define('admin-permissions', AdminPermissions);


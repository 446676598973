import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';

import { LocalizeNova } from '../../../../../shared/mixins/localize-nova/localize-nova.js';

class EditPermissions extends LocalizeNova(SkeletonMixin(LitElement)) {
  static get properties() {
    return {
      // Add properties here
    };
  }

  static get styles() {
    return css`
      /* Add styles here */
    `;
  }

  render() {
    return html`
      <div>
        <p>ye can edit permissions here</p>
      </div>
    `;
  }
}

window.customElements.define('edit-permissions', EditPermissions);


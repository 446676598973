import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/form/form.js';

import '../../../../../shared/components/general/nova-tooltip/nova-tooltip.js';
import '../../../../../admin/components/tax-nexus-input/tax-nexus-input.js';

import { css, html, LitElement } from 'lit';
import { bodySmallStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { navigator as nav } from 'lit-element-router';
import { radioStyles } from '@brightspace-ui/core/components/inputs/input-radio-styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { createModel } from '../../../../../../shared/models/models.js';
import { LocalizeNova } from '../../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaFormMixin } from '../../../../../shared/mixins/nova-form-mixin/nova-form-mixin.js';

export default class ManageProviderFinance extends NovaFormMixin(LocalizeNova(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      tenant: { type: Object, reflect: false },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      inputLabelStyles,
      radioStyles,
      selectStyles,
      css`
        .submit-button {
          padding-top: 10px;
        }
`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  firstUpdated() {
    // Initialize NovaFormMixin properties
    this.hideFormErrorSummary = false;
    this.showErrorToast = true;
  }

  render() {
    return html`
      <d2l-form>
        <provider-finance-fields
          .tenant=${this.tenant}
          @update-finance-fields=${this._dispatchUpdateTenant}></provider-finance-fields>
        <tax-nexus-input .taxNexus="${this.tenant.taxNexus}" @nova-tax-nexus-updated="${this._taxNexusUpdated}"></tax-nexus-input>
        <div id="submit-budget" class="submit-button">
          <d2l-button @click=${this._save} primary>${this.localize('manage-general.buttonSave')}</d2l-button>
        </div>
      </d2l-form>
    `;
  }

  _taxNexusUpdated(e) {
    this.tenant.taxNexus = e.detail.taxNexus;
    this._dispatchUpdateTenant();
  }

  _dispatchUpdateTenant() {
    this.dispatchEvent(new CustomEvent('update-tenant', { detail: { tenant: this.tenant } }));
  }

  async _save() {
    const isFormValidated = await this.isFormValidated();
    const toast = { message: 'Tenant finance info saved', type: 'default' };
    if (!isFormValidated) return;

    try {
      this.tenant = createModel(await this.client.updateGeneral(this.tenant));
    } catch (e) {
      toast.message = await e.text();
      toast.type = 'critical';
    }

    this.session.toast(toast);
  }
}

window.customElements.define('manage-provider-finance', ManageProviderFinance);

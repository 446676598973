import ActivitiesHelper from '../../../helpers/activities.js';
import { ALL_STATIC_STREAMS } from '../../../helpers/package-manager/packageStreamDefinitions.js';
import { Cost } from '../../cost.js';
import { formatIsoDate } from '../../../helpers/dateTime.js';
import { getDefinedStreamsPath } from '../../../helpers/package-manager/dynamicStreamDefinitions.js';
import { mapify } from '../../../methods.js';
import { novaLocalize } from '../../../l10n/localize.js';
import { SchemaAttribute } from '../nova-model-schema.js';

export const VALID_TAGS = [
  'active',
  'admissionBased',
  'allowRequest',
  'elective',
  'fastToComplete',
  'personalizedFeedback',
  'required',
  'submitMyOwn',
  'taxable',
  'extractingSkills',
];

const VALID_ACTIVITY_DELIVERY = [
  'online',
  'blended',
  'inPerson',
  'inPersonLocationRestricted',
];

const VALID_ACTIVITY_DURATION = [
  'lessThanThreeMonths',
  'threeToSixMonths',
  'sixToTwelveMonths',
  'oneToTwoYears',
  'twoPlusYears',
];

const VALID_ACTIVITY_TYPES = [
  'program',
  'course',
];

export const VALID_ACTIVITY_CATEGORY_DETAILS = [
  ...ALL_STATIC_STREAMS,
  {
    id: 'myList',
    langTerm: 'general.stream.myList',
    path: 'myList',
  },
];

export const VALID_ACTIVITY_CATEGORY_DETAILS_MAP = mapify(VALID_ACTIVITY_CATEGORY_DETAILS, 'id');

export const VALID_ACTIVITY_CATEGORY = VALID_ACTIVITY_CATEGORY_DETAILS.map(c => c.id);

export const localizeStartDate = date => {
  return formatIsoDate(date, false, 'monthYear');
};

/**
 * Format start date based on start date type. dateHandler allows for further (different) formatting of date object.
 *
 * Note: When dealing with formatters - use the value passed in as a second parameter. The formatters are chained so it may have already had it's value formatted
 * Alternatively you can ignore the previous value and take it directly from the model.
 *
 * @returns Date formatted according to date type
 */
function _formatStartDate(activity, startDate) {
  const { startDateType } = activity;
  if (startDateType === 'anytime') return novaLocalize('activity.startDate.anytime');
  if (startDateType === 'comingSoon') return novaLocalize('activity.startDate.comingSoon');
  if (!activity.isScheduled() && startDateType !== 'date') return novaLocalize('activity.startDate.notScheduled');
  const nextSession = localizeStartDate(ActivitiesHelper.getNextSessionDateAsString(startDate));
  if (nextSession === '') return novaLocalize('activity.startDate.comingSoon');
  return nextSession;
}

export const denormalizedActivityAttributes = {
  id: new SchemaAttribute(),
  description: new SchemaAttribute(),
  cardImageUrl: new SchemaAttribute(),
  category: new SchemaAttribute([]).setPossibleValues(VALID_ACTIVITY_CATEGORY),
  cost: new SchemaAttribute(),
  tempCost: new SchemaAttribute().setType(Cost),
  delivery: new SchemaAttribute().setPossibleValues(VALID_ACTIVITY_DELIVERY),
  duration: new SchemaAttribute().setPossibleValues(VALID_ACTIVITY_DURATION),
  provider: new SchemaAttribute(),
  maxLearnerDistance: new SchemaAttribute(),
  institution: new SchemaAttribute(),
  startDate: new SchemaAttribute().addFormatter(_formatStartDate),
  endDate: new SchemaAttribute().addFormatter(_formatStartDate),
  url: new SchemaAttribute(),
  startDateType: new SchemaAttribute(),
  skills: new SchemaAttribute([]),
  tags: new SchemaAttribute().setPossibleValues(VALID_TAGS),
  title: new SchemaAttribute(),
  type: new SchemaAttribute().setPossibleValues(VALID_ACTIVITY_TYPES),
  admissionRequirements: new SchemaAttribute(),
};

export const denormalizedActivityAttributeKeys = Object.keys(denormalizedActivityAttributes);

/**
 * Gets stream path for provided stream id
 * @param {*} streamId - one of the ids of the items in VALID_ACTIVITY_CATEGORY_DETAILS or defined streams
 * @returns the path for the stream, or undefined if not found
 */
export const getStreamPath = streamId => {
  const allStreams = VALID_ACTIVITY_CATEGORY_DETAILS.concat(getDefinedStreamsPath());
  const streamDetails = allStreams.find(str => str.id === streamId);
  return streamDetails?.path;
};

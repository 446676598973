import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui-labs/multi-select/multi-select-input-text.js';
import '@brightspace-ui-labs/multi-select/multi-select-list-item.js';
import '@brightspace-ui/core/components/form/form.js';

import '../../../../shared/components/activities/activity-link/activity-link.js';
import './email-list-input/email-list-input.js';

import { css, html, LitElement, nothing } from 'lit';
import { bodySmallStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaFormMixin } from '../../../../shared/mixins/nova-form-mixin/nova-form-mixin.js';
import { novaLottieMixin } from '../../../../shared/mixins/nova-lottie-mixin/nova-lottie-mixin.js';

class SuggestActivity extends NovaFormMixin(LocalizeNova(SkeletonMixin(RequesterMixin(novaLottieMixin(nav(LitElement)))))) {

  static get properties() {
    return {
      context: { type: String },
      expanded: { type: Boolean },
      isButtonPrimary: { type: Boolean },
      activity: { type: Object },
      baseMessage: { type: String },
      disableLink: { type: Boolean, attribute: 'disable-link', reflect: true },
      emails: { type: Array },
      valid: { type: Boolean },
      hidden: { type: Boolean, reflect: true },
      suggestOrigin: { type: String },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      super.styles,
      css`
        :host {
          background-color: #ffffff;
          border-radius: 0.4rem;
        }

        :host[hidden] {
          display: none;
        }

        .suggest-form-input {
          padding-bottom: 1.2rem;
        }

        .activity-link {
          margin-bottom: 1.2rem;
        }

        .hidden-animation {
          display: none;
        }

        .panel-wrapper {
          height: 100%;
          position: relative;
          width: 100%;
        }

        .call-to-action-text {
          font-size: 16px;
          line-height: 24px;
          margin: 0 0 0.8rem 0;
        }

        .popup-message {
          color: #266af6;
          font-family: 'Lato', sans-serif;
          font-size: 30px;
          font-style: normal;
          font-weight: 400;
          text-align: center;
          width: 100%;
          z-index: 1000;
        }

        .popup-message-sub {
          color: #202122;
          font-family: 'Lato', sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          margin-top: 10px;
          text-align: center;
          width: 100%;
          z-index: 1000;
        }

        /* putting screen reader notification off screen */

        .off-screen {
          height: 1px;
          left: -9999px;
          overflow: hidden;
          position: absolute;
          top: auto;
          width: 1px;
        }

        /* Component Animation */

        .fade-away {
          opacity: 0.1;
          pointer-events: none;
          -webkit-transition: opacity 0.2s ease-out;
          transition: opacity 0.2s ease-out;
          user-select: none;
        }

        .fade-back {
          opacity: 1;
          -webkit-transition: opacity 0.2s ease-out;
          transition: opacity 0.2s ease-out;
        }

        /* Sent Animation */

        @-webkit-keyframes fade-in-up {
          from {
            transform: translate3d(0, 20px, 0);
          }

          to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
          }
        }

        @keyframes fade-in-up {
          from {
            transform: translate3d(0, 20px, 0);
          }

          to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
          }
        }

        .animated {
          -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
          -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
        }

        .animated-fade-in-up {
          margin-top: 10px;
          opacity: 0;
          position: absolute;
          top: 60%;
          width: 100%;
        }

        @media (prefers-reduced-motion: reduce) {
          .overlay {
            bottom: 40%;
            height: 55%;
            position: absolute;
            width: 100%;
            z-index: 1000;
          }

          .fade-in-up {
            opacity: 1;
          }

        }

        @media (prefers-reduced-motion: no-preference) {
          .overlay {
            bottom: 5%;
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: 1000;
          }

          .fade-in-up {
            -webkit-animation-name: fade-in-up;
            animation-name: fade-in-up;
            opacity: 0;
          }

        }
`,
    ];
  }

  constructor() {
    super();
    this.resetState();
    this.hideFormErrorSummary = true;
    this.showNovaErrorSummary = true;
    this.initialMessageSet = false;
    this.skeleton = true;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
    this.hidden = this.session.hasFeature('marketing');
  }

  firstUpdated() {
    this.container = this.shadowRoot.getElementById('animation-container');
    this.animationUrl = '/assets/animation/suggest-animation.json';
    this.loop = false;
    this.autoPlay = false;
    super.firstUpdated();
    this.anim.onComplete = () => {
      this.resetState();
      this.baseMessage = this.localize(`suggest-panel.form.message.${this.suggestType}`);
    };
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);
    if (_changedProperties['activity'] === undefined && this.activity) {
      this.suggestType = `${this._messageType}.${this.activity?.type}`;

      if (!this.initialMessageSet) {
        this.baseMessage = this.localize(`suggest-panel.form.message.${this.suggestType}`);
      }
    }

    //  only remove skeleton once activity has loaded fully
    if (this._changedProperties?.activity?.id === undefined && this.activity?.id) {
      this.skeleton = false;
    }

    if (this.baseMessage) this.initialMessageSet = true;
  }

  preloadSuggestionData() {
    if (this.session.isPublicPortalGuest || this.emails.length > 0 || this.baseMessage !== this.localize(`suggest-panel.form.message.${this.suggestType}`)) {
      return;
    }

    const localStorageState = JSON.parse(localStorage.getItem('suggest-activity') || '[]').find(suggestion => suggestion.activityId === this.activity.id);
    if (localStorageState) {
      this.baseMessage = localStorageState.message;
      this.initialMessageSet = true;
      this._emailListChanged({ detail: { emails: localStorageState.emails } });
    }
  }

  get _messageType() {
    return this.context === 'taking' ? 'taking' : 'found';
  }

  get _suggestCourseTemplate() {
    const reducedMotionEnabled = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    const pageLocalizationPrefix = this.suggestOrigin === 'viewApplication' ? 'request-page' : 'non-request-page';
    const callToActionText = this.localize(`suggest-panel.${pageLocalizationPrefix}.description.${this.activity?.type}`);
    this.preloadSuggestionData();

    if (!this.activity?.hasTag('submitMyOwn')) {
      return html`
          <d2l-collapsible-panel ?skeleton="${this.skeleton}" ?expanded="${this.expanded}" id="parent" @d2l-collapsible-panel-expand="${this._panelClicked}" panel-title="${this.localize(`suggest-panel.title.${this.activity?.type}`)}">
            <div id="screen-reader">
            </div>
            <d2l-icon-custom slot="before" class="inline-icon" size="tier3">
              <img alt="" src="/assets/img/paper-plane.svg">
            </d2l-icon-custom>
            <d2l-collapsible-panel-summary-item ?skeleton="${this.skeleton}" slot="summary" text="${callToActionText}"></d2l-collapsible-panel-summary-item>
            <div class="panel-wrapper d2l-skeletize">
              <div class=${this.sending && reducedMotionEnabled ? 'overlay' : 'hidden-animation'}>
                  <img alt='SkillsWave with fireworks in background' width='100%' height='100%' src="/assets/img/wave-anim-reduced-motion.svg">
                </div>
              <div class=${this.sending && !reducedMotionEnabled ? 'overlay' : 'hidden-animation'} id="animation-container"></div>
              <div class=${this.sending ? 'animated animated-fade-in-up fade-in-up' : 'hidden-animation'}>
                <div class="popup-message">${this.localize('suggest-panel.sent.popup.header')}</div>
                <div class="popup-message-sub">${this.localize(`suggest-panel.sent.popup.subText.${this.activity?.type}`)}</div>
              </div>
              <div class=${this.sending ? 'fade-away' : 'fade-back'}>
              ${this.suggestOrigin === 'viewApplicationConfirmation' ? html`<p class="call-to-action-text">${callToActionText}</p>` : nothing}
                <activity-link
                  class="activity-link"
                  target="_blank"
                  .activity="${this.activity}"
                  .disabled="${this.disableLink}">
                </activity-link>
                <d2l-form id="suggestForm">
                  <email-list-input
                    required
                    class="suggest-form-input"
                    label="${this.localize('suggest-panel.form.label.email')}"
                    .emails=${this.emails}
                    @emails-changed="${this._emailListChanged}">
                  </email-list-input>
                  <d2l-input-textarea
                    id="message"
                    class="suggest-form-input"
                    rows="1"
                    label="${this.localize('suggest-panel.form.label.textarea')}"
                    @keyup="${this._messageChanged}"
                    .value="${this.baseMessage}">
                  </d2l-input-textarea>
                  <d2l-button primary="${ifDefined(this.isButtonPrimary)}"
                    .disabled="${!this.valid}"
                    @click="${this._submit}">
                    ${this.localize(`suggest-panel.form.button.${this.activity?.type}`)}
                  </d2l-button>
                </d2l-form>
              </div>
            </div>
          </d2l-collapsible-panel>`;
    } {
      return nothing;
    }
  }

  async _emailListChanged(e) {
    this.emails = e.detail.emails;
    this.valid = await this.isFormValidated();
  }

  async _messageChanged(e) {
    if (e.target.value !== this.baseMessage) this.customMessage = true;
    this.baseMessage = e.target.value;
  }

  _panelClicked() {
    this.client.logEvent({
      eventType: 'suggestionPanelClicked',
      activityId: this.activity.id,
      pageSuggestedFrom: this.suggestOrigin,
    });
  }

  resetState() {
    this.emails = [];
    this.valid = false;
    this.sending = false;
    this.customMessage = false;
  }

  async _submit() {
    // Public Portal: Allow user to enter suggestion, but force login before sending
    // Use of localStorage to store suggestion to send after login
    const suggestions = [...JSON.parse(localStorage.getItem('suggest-activity') || '[]')].filter(suggestion => suggestion.activityId !== this.activity.id);
    if (this.session.isPublicPortalGuest) {
      const activitySuggestion = { activityId: this.activity.id, emails: this.emails, message: this.baseMessage };
      localStorage.setItem('suggest-activity', JSON.stringify([...suggestions, activitySuggestion]));

      this.session.login(`${window.location.pathname}?suggestActivity=true`);
      return;
    }

    this.valid = this.isFormValidated();
    this.sending = true;
    this.playAnimation();
    const message = this.baseMessage;

    this.client.logEvent({
      eventType: 'activitySuggestionSubmitted',
      activityId: this.activity.id,
      numberOfRecipients: this.emails.length,
      customMessage: this.customMessage,
      pageSuggestedFrom: this.suggestOrigin,
    });

    // adding screen reader text to animation //
    try {
      await this.playAnimation();
      const element = this.shadowRoot.getElementById('screen-reader');
      element.innerHTML = `<h2 id="animation-voiceover" role="alert" class="off-screen">${this.localize('suggest-panel.sent.popup.header')} ${this.localize(`suggest-panel.sent.popup.subText.${this.activity?.type}`)}</h2>`;
    } catch (error) {
      console.error(error);
    }

    await this.client.sendSuggestion(this.activity.id, this.emails, message);

    // Once the suggestion is sent, remove the saved content from localStorage
    if (suggestions.length > 0) {
      localStorage.setItem('suggest-activity', JSON.stringify(suggestions));
    } else {
      localStorage.removeItem('suggest-activity');
    }
  }

  render() {
    return this.hidden ? nothing : this._suggestCourseTemplate;
  }

}

window.customElements.define('suggest-activity', SuggestActivity);

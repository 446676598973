import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/dialog/dialog.js';

import '../../../../../shared/components/general/nova-card/nova-card.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { bodyCompactStyles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { linkStyles } from '@brightspace-ui/core/components/link/link.js';

import { formatIsoDate } from '../../../../../../shared/helpers/dateTime.js';
import { LocalizeNova } from '../../../../../shared/mixins/localize-nova/localize-nova.js';
import { sharedC2AStyles } from '../shared-styles.js';
import { statuses } from '../../../../../../shared/models/application/index.js';

class RequesterCallToAction extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      activity: { type: Object, reflect: false },
      application: { type: Object, reflect: false },
      provider: { type: Object, reflect: false },
      accountConnectionEmail: { type: String, reflect: false },
      accountConnectionStudentID: { type: String, reflect: false },
      tenantType: { type: String, reflect: false },
      tenant: { type: Object, reflect: false },
    };
  }

  static get styles() {
    return [
      bodyCompactStyles,
      heading3Styles,
      sharedC2AStyles,
      linkStyles,
      css`
        h3.d2l-heading-3 {
          margin: 0 0 6px 0;
        }

        .c2a-red {
          background-color: var(--d2l-color-cinnabar-plus-2);
        }

        .c2a-registration {
          align-items: center;
          display: flex;
          gap: 30px;
          justify-content: space-between;
        }

        .first-container {
          display: flex;
        }

        .action {
          align-items: center;
          display: flex;
        }

        .tada {
          margin-left: calc(37.5px - 1rem);
          margin-right: 37.5px;
        }

        .body-text {
          overflow-wrap: break-word;
        }

        .centered-header {
          align-items: center;
          display: flex;
          flex-direction: column;
          height: 62px;
          justify-content: center;
        }

        @media (max-width: 767px) {
          .tada {
            margin-bottom: 1rem;
            margin-left: unset;
            margin-right: unset;
          }

          .c2a-registration {
            flex-direction: column;
          }

          .first-container {
            flex-direction: column;
          }

          .register-button {
            margin-top: 1rem;
          }
        }

        @media (max-width: 615px) {
          .d2l-body-compact {
            overflow: auto;
            overflow-wrap: break-word;
          }
        }
`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  get STATUS() {
    return Object.keys(statuses).reduce((prev, statusKey) => {
      prev[statusKey] = statuses[statusKey].label;
      return prev;
    }, {});
  }

  get isPaymentHandledByProvider() {
    return this.session?.tenant?.hasTag('paymentHandledByProvider');
  }

  // Check if activity start date is valid for registration
  get isRegistrationOpen() {
    return !this.activity.isPastStartDate() && this.activity.isScheduled();
  }

  render() {
    let template;

    switch (this.application.status.label) {
      case this.STATUS.PENDING:
        template = this.pendingTemplate();
        break;
      case this.STATUS.APPROVED:
        // approved status template
        template = this.isRegistrationOpen
          ? this.getApprovedTemplate()
          : this.registrationClosedTemplate();

        break;
      case this.STATUS.ONE_OF_TWO_APPROVED:
        template = this.oneOfTwoApprovedTemplate();
        break;
      case this.STATUS.REGISTRATION_SUBMITTED:
        template = this.registrationSubmittedTemplate();
        break;
      case this.STATUS.WITHDRAWN:
        template = this.withdrawnTemplate();
        break;
      case this.STATUS.CANCELLED_AFTER_REGISTRATION:
      case this.STATUS.CANCELLED_BEFORE_REGISTRATION:
        if (this.application.cancelledBy === 'Employee') { return nothing; }
        template = this.cancelledTemplate();
        break;
      case this.STATUS.FAILED:
        template = this.failedTemplate();
        break;
      default:
        // If application status is none of the above, don't show CTA (i.e. completed status)
        template = null;
    }

    if (template === null) return nothing;

    const cardStyle = this._hasNegativeRedStatus ? 'c2a-red' : 'c2a-gray';
    return html`
      <nova-card .skeleton=${this.skeleton} class="call-to-action ${cardStyle}">
        <div slot="primary">
          ${template}
        </div>
      </nova-card>
    `;
  }

  get _hasNegativeRedStatus() {
    return this.application?.isFailed || this.application?.isCancelled || this.application?.isWithdrawn;
  }

  oneOfTwoApprovedTemplate() {
    if (this.application.hasManagerResponded()) {
      return this.localize('application-call-to-action.template.requester.oneOfTwoApproved.role.sponsor');
    } else {
      return this.localize('application-call-to-action.template.requester.oneOfTwoApproved.role.manager');
    }
  }

  withdrawnTemplate() {
    const formattedCompletionDate = formatIsoDate(this.application.completedDate);
    const localizeArgs = { activityTitle: this.activity.title, formattedCompletionDate };
    return this.localize('application-call-to-action.template.requester.withdrawn', localizeArgs);
  }

  pendingTemplate() {
    return this.localize('application-call-to-action.template.requester.pending');
  }

  cancelledTemplate() {
    const formattedCompletionDate = formatIsoDate(this.application.completedDate);
    const localizeArgs = { providerName: this.provider?.name, formattedCompletionDate };
    return this.localize('application-call-to-action.template.requester.cancelled', localizeArgs);
  }

  failedTemplate() {
    return this.localize('application-call-to-action.template.requester.failed');
  }

  registrationSubmittedTemplate() {
    const studentSupportEmail = this.provider.studentSupportEmail;
    const link = this.isPaymentHandledByProvider ? this.l10nTags.d2lLink(`mailto:${studentSupportEmail}`) : this.l10nTags.d2lLink('/contact-support');
    const eventType = 'link';

    const registrationSubmittedHeading = this.localize(`application-call-to-action.template.requester.paid.heading${this.isPaymentHandledByProvider ? '.isPaymentHandledByProvider' : ''}`, { providerName: this.provider?.name, redirectLink: this.l10nTags.d2lOnClick(() => this.dispatchRedirectDialogEvent(eventType)) });
    const registrationSubmittedText = this.localize(`application-call-to-action.template.requester.paid.text${this.isPaymentHandledByProvider ? '.isPaymentHandledByProvider' : ''}`, { email: this.application?.user?.getEmail(), institution: this.provider?.name, registrationTime: this.provider?.registrationTime, link: link, studentSupportEmail: studentSupportEmail });

    return html`
      <div>
        <h3 class="d2l-heading-3">${registrationSubmittedHeading}</h3>
        ${(!studentSupportEmail || studentSupportEmail.trim() === '') && this.isPaymentHandledByProvider ? nothing : html`<div class="d2l-body-compact">${registrationSubmittedText}</div>` }
      </div>
    `;
  }

  getApprovedTemplate() {
    const providerName = this.provider.name;
    const { registrationTime } = this.provider;
    const admissionBased = this.application.activity.hasTag('admissionBased');

    // if account connection is enabled and the user has not yet provided an account connection email
    //  show CTA with "Next Steps" button that navigates to account connection form page
    if (this.hasNoAccountConnectionEmail || this.hasNoAccountConnectionStudentID) {
      return this.approvedTemplate({
        registerButtonText: this.localize('application-call-to-action.template.requester.accountConnection.button'),
        headingText: this.localize(`application-call-to-action.template.requester.accountConnection.heading${this.isPaymentHandledByProvider ? '.isPaymentHandledByProvider' : ''}${ !this.isPaymentHandledByProvider && admissionBased ? '.admissionBased' : ''}`, { providerName }),
        bodyText: this.localize(`application-call-to-action.template.requester.accountConnection.text${ admissionBased ? '.admissionBased' : ''}`, { providerName, registrationTime }),
      });
    }

    // otherwise just show the CTA with a button that navigates to Stripe checkout
    return this.approvedTemplate({
      registerButtonText: this.localize(`application-call-to-action.template.requester.approvedOrRegistered.registerButton.stripe.label${ admissionBased ? '.admissionBased' : ''}`),
      headingText: this.localize(`application-call-to-action.template.requester.accountConnection.heading${this.isPaymentHandledByProvider ? '.isPaymentHandledByProvider' : ''}${ !this.isPaymentHandledByProvider && admissionBased ? '.admissionBased' : ''}`, { providerName }),
      bodyText: this.isPaymentHandledByProvider ? '' : this.localize(`application-call-to-action.template.requester.approvedOrRegistered.text.stripe${ admissionBased ? '.admissionBased' : ''}`, { providerName, registrationTime }),
    });
  }

  get hasNoAccountConnectionEmail() {
    return this.provider.hasTag('accountConnection') && !this.accountConnectionEmail;
  }

  get hasNoAccountConnectionStudentID() {
    return this.provider.hasTag('requireStudentID') && !this.accountConnectionStudentID;
  }

  approvedTemplate({ registerButtonText, bodyText, headingText }) {
    const isAdmin = this.tenantType === 'admin';
    return html`
      <div class="c2a-registration">
        <div class="first-container">
          <img class="tada" height="68" src="/assets/img/tada.png" alt="">
          <div>
            <h3 class="d2l-heading-3 ${this.isPaymentHandledByProvider ? 'centered-header' : ''}">
              ${headingText}
            </h3>
            ${this.isPaymentHandledByProvider ? '' : html`
              <div class="body-text d2l-body-compact">
                ${bodyText}
              </div>`}
          </div>
        </div>
        <!-- Call to action Button -->
        <div>
          <a target="_blank" rel="noopener" @click=${this.registerButtonClicked}>
            <d2l-button aria-label="${registerButtonText}" ?disabled=${isAdmin} class="register-button" primary>
              ${registerButtonText}
            </d2l-button>
          </a>
        </div>
      </div>
    `;
  }

  registrationClosedTemplate() {
    const headingText = this.localize('application-call-to-action.template.requester.approved.registrationClosed.heading');
    const bodyText = this.localize('application-call-to-action.template.requester.approved.registrationClosed.bodyText');

    const registerButtonLangKey = (this.hasNoAccountConnectionEmail || this.hasNoAccountConnectionStudentID)
      ? 'application-call-to-action.template.requester.accountConnection.button'
      : 'application-call-to-action.template.requester.approvedOrRegistered.registerButton.stripe.label';
    const registerButtonText = this.localize(registerButtonLangKey);

    return html`
      <div class="c2a-registration">
        <div>
          <h3 class="d2l-heading-3">
            ${headingText}
          </h3>
          <div class="body-text d2l-body-compact">
            ${bodyText}
          </div>
        </div>
        <div class="action">
          <d2l-button aria-label="${registerButtonText}" class="register-button" primary disabled>
              ${registerButtonText}
            </d2l-button>
          </a>
        </div>
      </div>
    `;
  }

  dispatchRedirectDialogEvent(eventType) {
    this.dispatchEvent(new CustomEvent('redirect-dialog-open',
      {
        bubbles: true,
        composed: true,
        detail: { eventType },
      }
    ));
  }

  async registerButtonClicked() {
    const eventType = 'button';
    const shouldRequireStudentID = this.provider.hasTag('requireStudentID') && !this.accountConnectionStudentID;
    if (this.tenantType === 'employer' && this.tenant.hasTag('paymentHandledByProvider')) {
      this.dispatchRedirectDialogEvent(eventType);
      return;
    }
    if (this.provider.hasTag('accountConnection') && (!this.accountConnectionEmail || shouldRequireStudentID)) {
      // if the user does not yet have an account connection email
      // we need to navigate to the form and ensure they provide one before checking out
      this.navigate(`/requests/${this.application.uuid}/account-connection`);
    } else {
      const { amount } = await this.client.convertCostToEmployee(this.application.uuid);
      // if employee cost is greater than 50 cents
      if (amount >= 0.50) {
        // navigate to the Stripe checkout page
        const response = await this.client.createPaymentFlow({ uuid: this.application.uuid });
        location.replace(response.sessionUrl);
      } else {
        // otherwise just set the application as paid
        // Stripe does not support checkouts for less than 50 cents and we eat the cost
        await this.client.setPaymentDate(this.application.uuid);
        this.navigate(`/requests/${this.application.uuid}/confirmation`);
      }
    }
  }

}

window.customElements.define('requester-call-to-action', RequesterCallToAction);

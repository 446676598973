import '@brightspace-ui/core/components/alert/alert.js';
import '@brightspace-ui/core/components/button/button-subtle.js';
import '@brightspace-ui/core/components/collapsible-panel/collapsible-panel.js';
import '@brightspace-ui/core/components/collapsible-panel/collapsible-panel-group.js';
import '@brightspace-ui/core/components/collapsible-panel/collapsible-panel-summary-item.js';
import '@brightspace-ui/core/components/html-block/html-block.js';
import '@brightspace-ui/core/components/icons/icon-custom.js';

import { bodyCompactStyles, bodySmallStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { linkStyles } from '@brightspace-ui/core/components/link/link.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { css, html, LitElement, nothing } from 'lit';
import DOMPurify from 'dompurify';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { Cost } from '../../../../../shared/models/cost.js';
import { DEFAULT_CURRENCY } from '../../../../../shared/constants.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { AdmissionRequirements as Requirements } from '../../../../../shared/models/activity/admission-requirements.js';
/**
 * Custom admin provided definition of an individual admission requirement for an activity
 * @typedef {Object} requirement
 * @property {import('lit').TemplateResult} description - Manually input description of requirement by admin
 * @property {string} title - Title of admission requirement
 * @property {number} [cost] - Indicates a possible, approximate amount that the requirement will cost
 * @property {number} [delay] - Indicates whether if there is a processing delay involved with the requirement
 */

class AdmissionRequirements extends LocalizeNova(RequesterMixin(SkeletonMixin(LitElement))) {

  static get properties() {
    return {
      isApplicant: { type: Boolean },
      lang: { type: String },
      providerCurrency: { type: String },
      requirements: { type: Object },
      pageName: { type: String },
      numOfRequirements: { type: Number },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodyCompactStyles,
      bodySmallStyles,
      linkStyles,
      css`
        :host {
          display: block;
        }
        .international-announcement {
          margin: 0 0.9rem;
          padding: 1.8rem 0;
        }
        .international-announcement p {
          margin-block: 0;
        }
        d2l-alert {
          margin-top: 0.9rem;
          max-width: 100%;
        }
        .card-content {
          align-items: center;
          border: 1px solid var(--d2l-color-mica);
          border-radius: 0.4rem;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          gap: 1.5rem;
          margin-top: 1.8rem;
          padding-left: 1.875rem;
          width: 100%;
        }
`,
    ];
  }

  constructor() {
    super();
    this.isApplicant = true;
    this.lang = 'en';
    this.providerCurrency = DEFAULT_CURRENCY;
    this.requirements = new Requirements();
    this.numOfPanelClicks = 0;
    this.initialTime = new Date().getTime();
    this.ignoreBrowserUnloadEvent = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._handleDisconnect();
  }

  _handleDisconnect() {
    const timeSpent = (new Date().getTime() - this.initialTime) / 1000; // converted from milliseconds to seconds
    this._handleAmplitudeEvent('leavingAdmissionRequirementsPage', {
      timeSpent,
      numOfPanelsOpened: `${this.numOfPanelClicks}/${this.numOfRequirements}`,
      pageView: this.pageName,
    });
  }

  render() {
    if (!this.requirements) return nothing;

    return html`
      <div class="requirements">
        ${this._standardRequirementsTemplate()}
        ${this._internationalRequirementsTemplate()}
        ${this._contactEmailTemplate()}
      </div>
    `;
  }

  _contactEmailTemplate() {
    if (!this.requirements?.contactEmail) return nothing;

    const mailto = `mailto:${this.requirements.contactEmail}`;

    return html`
      <div class="card-content">
        <d2l-icon-custom size="tier3">
          <svg xmlns="http://www.w3.org/2000/svg" width="29.926" height="17.202" viewBox="0 0 29.926 17.202">
            <path id="Path_291" data-name="Path 291" d="M.949-61.911,5.869-67.2a1.709,1.709,0,0,1,1.252-.545h9.86a1.678,1.678,0,0,1,1.678,1.678,1.678,1.678,0,0,1-.011.191l-.04.353c-.1.863.244,1.5-.7,1.515H13.458c-.518-.019-1.3-.119-1.3.348,0,.49.713.287,1.3.324.031,0,1.319.006,1.351.006h4.013a.855.855,0,0,0,.675-.33l5.4-5.955a1.709,1.709,0,0,1,2.189-.44l.27.152a1.55,1.55,0,0,1,.589,2.111,1.551,1.551,0,0,1-.12.182l-7.241,9.446a1.709,1.709,0,0,1-1.357.669H9.3a1.709,1.709,0,0,0-1.3.6l-.833.973a1.709,1.709,0,0,1-2.41.188q-.054-.046-.1-.1L.985-59.545A1.709,1.709,0,0,1,.949-61.911Z" transform="translate(0.654 71.368)" fill="none" stroke="#565A5C" stroke-width="2" fill-rule="evenodd"/>
          </svg>
        </d2l-icon-custom>
        <p>${this.localize('admission.requirements.contactEmailHelpCard')}
          <a id="requirementsEmail"
          class="d2l-link"
          href="${mailto}"
          data-category="admissionRequirementsEmailClicked"
          @click="${this._handleEmailEvent}"
          >
            ${this.requirements.contactEmail}
          </a>
        </p>
      </div>
    `;
  }

  _handleEmailEvent(_event) {
    const eventType = _event.target.getAttribute('data-category');
    this._handleAmplitudeEvent(eventType);
  }

  _handlePanelOpenedEvent(_event) {
    const eventType = _event.target.getAttribute('data-category');
    this.numOfPanelClicks++;
    this._handleAmplitudeEvent(`${eventType}PanelOpened`);
  }

  _handleAmplitudeEvent(eventType, additionalAttr = {}) {
    if (this.session.tenant.type === 'admin' || this.session.tenant.type === 'provider') return;
    this.client.logEvent({
      eventType: eventType,
      ...additionalAttr,
    });
  }

  _costDelayTemplate(str) {
    if (!str) return nothing;
    return html`
      <d2l-collapsible-panel-summary-item slot="summary" text=${str}>
      </d2l-collapsible-panel-summary-item>
      <d2l-alert>
        ${str}
      </d2l-alert>
    `;
  }

  _internationalRequirementsTemplate() {
    if (!this.requirements?.international?.length) return nothing;
    const category = 'internationalRequirements';
    const announcementStr = this.isApplicant
      ? this.localize('admission.requirements.internationalAnnouncement.applicant')
      : this.localize('admission.requirements.internationalAnnouncement.not.applicant');

    return html`
      <div class="d2l-body-standard international-announcement">
        <p class="d2l-skeletize d2l-skeletize-90">${announcementStr}</p>
      </div>
      <d2l-collapsible-panel-group>
        ${this._requirementListTemplateGenerator(this.requirements?.international, category)}
      </d2l-collapsible-panel-group>
    `;
  }

  _requirementTemplate({ title, description, cost, delay }, category) {
    const requirementCost = new Cost({ cost: 0, currency: this.providerCurrency });

    const costDelayString = !!cost || !!delay
      ? !!cost && !!delay
        ? this.localize('admission.requirements.hasCostAndDelay', { cost: requirementCost.setInDollars(cost).formatAsInt(this.lang, false), delay: delay })
        : cost
          ? this.localize('admission.requirements.hasCostOnly', { cost: requirementCost.setInDollars(cost).formatAsInt(this.lang, false) })
          : this.localize('admission.requirements.hasDelayOnly', { delay: delay })
      : null;

    return html`
      <d2l-collapsible-panel
      @d2l-collapsible-panel-expand=${this._handlePanelOpenedEvent}
      data-category=${category}
      type="inline" panel-title=${title}
      .skeleton=${this.skeleton}>
        <d2l-html-block html=${DOMPurify.sanitize(description)}></d2l-html-block>
        ${this._costDelayTemplate(costDelayString)}
      </d2l-collapsible-panel>`;
  }

  _standardRequirementsTemplate() {
    if (!this.requirements?.standard?.length) return nothing;
    const category = 'standardAdmissionRequirements';
    return html`
      <d2l-collapsible-panel-group>
        ${this._requirementListTemplateGenerator(this.requirements?.standard, category)}
      </d2l-collapsible-panel-group>
    `;
  }

  /**
  * Generates an array of `<d2l-collapsible-panel>`s populated with their own customized templates based on each requirement's definition
  * @param {requirement[]} requirements - List of requirements to be iterated over for template generation
  */
  _requirementListTemplateGenerator(requirements, category) {
    const reqTemplates = [];
    for (const req of requirements) {
      reqTemplates.push(this._requirementTemplate(req, category));
    }

    return reqTemplates;
  }

}

window.customElements.define('admission-requirements', AdmissionRequirements);

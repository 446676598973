import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui/core/components/tabs/tabs.js';
import '@brightspace-ui/core/components/tabs/tab-panel.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator.js';
import '@brightspace-ui-labs/accordion/accordion.js';
import '@brightspace-ui-labs/accordion/accordion-collapse.js';
import '@brightspace-ui/core/components/button/button-subtle.js';
import '@brightspace-ui/core/components/button/button.js';

import './features-grid/features-grid.js';
import './admin-permissions/admin-permissions.js';
import '../../../shared/components/general/app-link/app-link.js';
import '../../../shared/components/activities/activity-table/activity-table.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading1Styles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';
import { tableStyles } from '@brightspace-ui/core/components/table/table-wrapper.js';

import ActivitiesHelper from '../../../../shared/helpers/activities.js';

import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';

/**
 * The view for showing a list of tenants
 */
export default class ViewAdmin extends LocalizeNova(RequesterMixin(nav(LitElement))) {

  static get properties() {
    return {
      _tenants: { type: Array, attribute: false },
      _selectedType: { type: String, attribute: false },
      _isSkillsLoading: { type: Boolean, attribute: false },
      _filteredSkills: { type: Array, attribute: false },
    };
  }

  static get styles() {
    return [
      selectStyles,
      inputLabelStyles,
      heading1Styles,
      heading2Styles,
      heading3Styles,
      tableStyles,
      css`
        :host {
          display: block;
        }

        d2l-tabs {
          max-width: 1170px;
          width: 90vw;
        }

        .tenant-list-item app-link {
          z-index: 1;
        }

        .add-tenant {
          margin-top: 25px;
        }

        .create-tenant {
          align-items: center;
          column-gap: 10px;
          display: grid;
          grid-template-columns: auto 1fr;
        }

        .tenant-list-item {
          align-items: center;
          display: grid;
          grid-template-columns: 30% auto;
          width: 100%;
        }

        .select-wrapper {
          display: flex;
          flex-wrap: wrap;
          width: min-content;
        }

        .select-wrapper > label {
          width: 100%;
        }

        .d2l-loading-spinner {
          text-align: center;
        }
        .skill-filter {
          margin-bottom: 10px;
        }
`,
    ];
  }

  constructor() {
    super();
    this._isSkillsLoading = false;
  }

  get permissionsTab() {
    return this.session.tenant.hasTag('adminRoles') ? html`
      <d2l-tab-panel text="${this.localize('view-admin.permissions')}">
        <admin-permissions></admin-permissions>
      </d2l-tab-panel>` : nothing;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
    this._tenants = await this.client.listTenants();
  }

  render() {
    if (this.session.loggedIn && this.session.tenant.type !== 'admin') {
      this.navigate('/');
    }

    if (!this._tenants) {
      return html`${this.localize('view-admin.loading')}`;
    }
    const providers = this._tenants.filter(tenant => tenant.type === 'provider');
    const employers = this._tenants.filter(tenant => tenant.type === 'employer');
    const admin = this._tenants.filter(tenant => tenant.type === 'admin');

    return html`
      <d2l-tabs>
        <d2l-tab-panel text="${this.localize('view-admin.activities')}" selected>
          <app-link class="add-button" href="activities/add"><d2l-button>${this.localize('general.addActivity')}</d2l-button></app-link>
          <activity-table .providers=${providers}></activity-table>
        </d2l-tab-panel>
        <d2l-tab-panel text="${this.localize('view-admin.tenants')}">
          <div class="create-tenant">
            <div class="select-wrapper">
              <label for="type" class="d2l-input-label d2l-input-label-required">${this.localize('view-admin.tenantTypeSelect.label')}</label>
              <select id="type" class="d2l-input-select" @change=${this._typeSelected}>
                <option disabled selected value=""> ${this.localize('view-admin.tenantTypeSelect.none')} </option>
                <option value="employer">${this.localize('general.employer')}</option>
                <option value="provider">${this.localize('general.provider')}</option>
              </select>
            </div>
            <app-link class="add-tenant" href=${this._selectedType ? `tenants/add/${this._selectedType}` : ''}>
              <d2l-button .disabled=${!this._selectedType}>${this.localize('view-admin.addTenant')}</d2l-button>
            </app-link>
          </div>
          <h1 class="d2l-heading-1">${this.localize('view-admin.providers.heading1')}</h1>
          ${this.tenantListTemplate(providers)}
          <h1 class="d2l-heading-1">${this.localize('view-admin.employers.heading1')}</h1>
          ${this.tenantListTemplate(employers)}
          <h1 class="d2l-heading-1">${this.localize('general.admin')}</h1>
          ${this.tenantListTemplate(admin)}
        </d2l-tab-panel>
        <d2l-tab-panel text="${this.localize('general.skills')}" >
          <d2l-button primary @click=${this._refreshSkillsLists}>${this.localize('view-admin.fetchSkills')}</d2l-button>

          ${this._isSkillsLoading ? html`<div class="d2l-loading-spinner"><d2l-loading-spinner></d2l-loading-spinner></div>` : this._skillsTableTemplate}

        </d2l-tab-panel>
        <d2l-tab-panel text="${this.localize('view-admin.features')}">
          <features-grid .tenants=${this._tenants}></features-grid>
        </d2l-tab-panel>
        ${this.permissionsTab}
      </d2l-tabs>
    `;
  }
  tenantListTemplate(tenants) {
    tenants.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    return html`
      <d2l-list>
      ${tenants.map(tenant => html`
        <d2l-list-item>
          <div class="tenant-list-item">
            ${tenant?.imageUrl ? html`
              <img height="25px" alt="${tenant.name}" src="${tenant.imageUrl}">
            ` : html`
              <span>${tenant.name}</span>
            `}
            <app-link d2l-link href="/tenants/${tenant.id}/edit"><div>${tenant.name}</div></app-link>
          </div>
        </d2l-list-item>
      `)}
      </d2l-list>
    `;
  }

  _updateSkillsFilter(e) {
    const filterValue = e.target.value;
    this._filteredSkills = this._skills.filter(skill => {
      if (skill.name.toLowerCase().includes(filterValue.toLowerCase())) return true;
      if (skill.id.toLowerCase().includes(filterValue.toLowerCase())) return true;
      if (skill.activityName.toLowerCase().includes(filterValue.toLowerCase())) return true;
      if (skill.activityId.toLowerCase().includes(filterValue.toLowerCase())) return true;
      if (skill.activityType.toLowerCase().includes(filterValue.toLowerCase())) return true;
      return false;
    });
  }

  get _skillsTableTemplate() {
    if (!this._skills || Object.keys(this._skills).length === 0) return nothing;
    return html`
      <d2l-input-text class="skill-filter" label="${this.localize('view-admin.filterSkills.label')}" @keyup="${this._updateSkillsFilter}"></d2l-input-text>
      <d2l-table-wrapper sticky-headers>
        <table class="d2l-table">
          <thead>
          <tr>
            <th>${this.localize('view-admin.filterSkills.table.skillId')}</th>
            <th>${this.localize('view-admin.filterSkills.table.skillName')}</th>
            <th>${this.localize('view-admin.filterSkills.table.activityName')}</th>
            <th>${this.localize('view-admin.filterSkills.table.activityId')}</th>
            <th>${this.localize('view-admin.filterSkills.table.activityType')}</th>
          </tr>
          </thead>
          <tbody>
          ${this._filteredSkills.map(skill => html`
            <tr>
              <td>${skill.id}</td>
              <td>${skill.name}</td>
              <td>${skill.activityName}</td>
              <td>${skill.activityId}</td>
              <td><d2l-status-indicator state="${skill.activityType === 'course' ? 'none' : 'default'}" text="${skill.activityType}" bold></d2l-status-indicator></td>
            </tr>
          `)}
          </tbody>
        </table>
      </d2l-table-wrapper>`;
  }

  async _refreshSkillsLists() {
    this._isSkillsLoading = true;
    this._activities = await this.client.activityList();

    this._skills = ActivitiesHelper.extractSkills(this._activities);
    this._filteredSkills = [...this._skills];

    this._isSkillsLoading = false;
  }

  _typeSelected(e) {
    this._selectedType = e.target.value;
  }
}

window.customElements.define('view-admin', ViewAdmin);

import { novaLocalize } from '../../l10n/localize.js';

const getDefinedStreams = (user, tenant) => {

  const tenantId = user?.tenantId;
  const lang = user?.settings?.language;
  const tenantName = tenant?.name;

  const trending = {
    displayName: novaLocalize('streams.property.trending', { tenantName: tenantName }, lang),
    id: 'trending',
    type: 'dynamic',
    path: 'company-trending',
    sort: {
      sortProperty: 'trendingTenants.sort',
      direction: 'asc',
      nested: {
        path: 'trendingTenants',
        term: 'trendingTenants.id',
        value: `${tenantId}`,
      },
    },
    property: {
      nested: {
        path: 'trendingTenants',
        name: 'trendingTenants.id',
        value: `${tenantId}`,
      },
    },
  };

  const recentlyAdded = {
    displayName: novaLocalize('streams.ordered.recentlyAdded', {}, lang),
    id: 'activeDate',
    type: 'dynamic',
    path: 'recently-added',
    sort: {
      sortProperty: 'activeDate',
      min: new Date(Date.now() + -1669895858).toISOString(), // 2 weeks in milliseconds
      max: new Date(Date.now()).toISOString(),
      direction: 'desc',
    },
  };

  const startingSoon = {
    displayName: novaLocalize('streams.ordered.startingSoon', {}, lang),
    id: 'startDate',
    type: 'dynamic',
    path: 'starting-soon',
    sort: {
      sortProperty: 'startDate',
      min: new Date(Date.now()).toISOString(),
      max: new Date(Date.now() + 1669895858).toISOString(), // 2 weeks in milliseconds
      direction: 'asc',
    },
  };

  const onDemand = {
    displayName: novaLocalize('general.stream.onDemand', {}, lang),
    id: 'onDemand',
    type: 'dynamic',
    path: 'on-demand',
    property: {
      name: 'startDateType',
      value: 'anytime',
    },
  };

  const streamsToReturn = [
    trending,
    recentlyAdded,
    startingSoon,
    onDemand,
  ];

  if (!tenant?.hasTag('hideFastToCompleteStream')) {
    const fastToComplete = {
      displayName: novaLocalize('streams.property.fastToComplete', {}, lang),
      id: 'fastToComplete',
      type: 'dynamic',
      path: 'fast-to-complete',
      property: {
        name: 'tags',
        value: 'fasttocomplete',
      },
    };
    streamsToReturn.push(fastToComplete);
  }

  return streamsToReturn;
};

const getDefinedStreamsPath = () => {
  return getDefinedStreams().map(stream => {
    return {
      id: stream.id,
      path: stream.path,
    };
  });
};

export {
  getDefinedStreams,
  getDefinedStreamsPath
};

import { css, html, LitElement } from 'lit';

import '@brightspace-ui/core/components/colors/colors.js';
import { FocusMixin } from '@brightspace-ui/core/mixins/focus-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { navigator as NavigatorMixin } from 'lit-element-router';

class NovaLinkCard extends SkeletonMixin(FocusMixin(NavigatorMixin(LitElement))) {

  static get properties() {
    return {
      href: { type: String, reflect: true },
      text: { type: String, reflect: true },

      active: { type: Boolean, reflect: true, attribute: 'active' },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          background-color: #ffffff;
          border: 1px solid var(--d2l-color-gypsum);
          border-radius: 6px;
          box-sizing: border-box;
          display: inline-block;
          position: relative;
          z-index: 0;
        }

        :host(:hover) {
          box-shadow: 0 2px 14px 1px rgba(0, 0, 0, 0.06);
          cursor: pointer;
        }
        :host([active]),
        :host([active]:hover) {
          border-color: transparent;
          box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--d2l-color-celestine);
        }
        /* .d2l-card-link-container-hover is used to only color/underline when
        hovering the anchor; these styles are not applied when hovering actions */
        :host([href]) .d2l-card-link-container-hover,
        :host([href][active]) .d2l-card-content {
          color: var(--d2l-color-celestine);
          text-decoration: underline;
        }

        @media (prefers-reduced-motion: no-preference) {
          :host {
            transition: transform 300ms ease-out 50ms, box-shadow 0.2s;
          }

          :host(:hover),
          :host([active]:hover) {
            transform: translateY(-4px);
          }
        }

        .nova-link-card-container {
          display: flex;
          outline: none;
          overflow: hidden;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.active = false;
    this._hover = false;
  }

  connectedCallback() {
    super.connectedCallback();

    this.addEventListener('click', this._onClick);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.removeEventListener('click', this._onClick);
  }

  static get focusElementSelector() {
    return 'div';
  }

  render() {
    return html`
      <div class="nova-link-card-container d2l-skeletize" tabindex="0" @keydown=${this._handleKeyDown} @focus=${this._onFocus} @blur=${this._onBlur}>
        <slot></slot>
      </div>
    `;
  }

  _onFocus() {
    this.active = true;
  }

  _onBlur() {
    this.active = false;
  }

  _handleKeyDown(e) {
    if (e.key === 'Enter' || e.key === ' ') {
      this._onClick();
    }
  }

  _onClick() {
    if (this.href) {
      this.navigate(this.href);
    }
  }
}

customElements.define('nova-link-card', NovaLinkCard);

import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import { tableStyles } from '@brightspace-ui/core/components/table/table-wrapper.js';

import { css, html, LitElement } from 'lit';
import { heading1Styles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { navigator as nav } from 'lit-element-router';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import '../../../../shared/components/general/no-results/no-results.js';
import { FEATURES } from '../../../../../shared/constants.js';

export default class FeaturesGrid extends RequesterMixin(nav(LitElement)) {

  static get properties() {
    return {
      tenants: { type: Array },
      _providers: { type: Array, reflect: false },
      _employers: { type: Array, reflect: false },
      _admin: { type: Array, reflect: false },
      _filteredProviders: { type: Array, reflect: false },
      _filteredAdmin: { type: Array, reflect: false },
      _filteredEmployers: { type: Array, reflect: false },
    };
  }

  static get styles() {
    return [
      selectStyles,
      tableStyles,
      heading1Styles,
      heading2Styles,
      heading3Styles,
      css`
        .checkbox-col {
          text-align: center !important;
          vertical-align: middle !important;
        }
`,
    ];
  }

  constructor() {
    super();
    this._updatedFeatures = {};
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  firstUpdated() {
    this._providers = this.tenants.filter(tenant => tenant.type === 'provider');
    this._employers = this.tenants.filter(tenant => tenant.type === 'employer');
    this._admin = this.tenants.filter(tenant => tenant.type === 'admin');
    this._filteredProviders = this._providers;
    this._filteredEmployers = this._employers;
    this._filteredAdmin = this._admin;
  }

  _updateSkillsFilter(e) {
    const filterValue = e.target.value;
    const tenantFilter = tenant => {
      return tenant.name.toLowerCase().includes(filterValue.toLowerCase());
    };

    this._filteredProviders = this._providers.filter(tenantFilter);
    this._filteredEmployers = this._employers.filter(tenantFilter);
    this._filteredAdmin = this._admin.filter(tenantFilter);
  }

  render() {
    return html`
      <d2l-input-text class="tenants-filter" label="Filter tenants" @keyup="${this._updateSkillsFilter}"></d2l-input-text>
      <h2 class="d2l-heading-2">Employers</h2>
      ${this._gridTemplate(this._filteredEmployers)}
      <h2 class="d2l-heading-2">Providers</h2>
      ${this._gridTemplate(this._filteredProviders)}
      <h2 class="d2l-heading-2">Admin</h2>
      ${this._gridTemplate(this._filteredAdmin)}
      <d2l-button primary @click=${this._saveFeatures}>Save</d2l-button>
    `;
  }

  _allFeaturesChanged(tenants, feature) {
    return e => {
      for (const tenant of tenants) {
        this._checkFeature(tenant, feature, e.target.checked);
      }
      this.update();
    };
  }

  _checkFeature(tenant, feature, value) {
    tenant.features[feature] = value;
    this._updatedFeatures[tenant.id] = tenant.features;
  }

  _featureCheckboxChanged(tenant, feature) {
    return e => {
      tenant.features[feature] = e.target.checked;
      this._updatedFeatures[tenant.id] = tenant.features;
    };
  }

  _gridTemplate(tenants) {
    if (!tenants || tenants.length === 0) return html`<no-results></no-results>`;
    const featureKeys = Object.keys(FEATURES);
    return html`
      <d2l-table-wrapper sticky-headers type="light">
        <table class="d2l-table">
          <thead>
          <tr>
            <th><d2l-table-col-sort-button>Tenant</d2l-table-col-sort-button></th>
            ${repeat(featureKeys, key => key, key => html`
              <th class="checkbox-col">${FEATURES[key]}</th>
            `)}
          </tr>
          <tr>
            <th></th>
            ${repeat(featureKeys, key => key, key => html`
              <th class="checkbox-col">
                <d2l-input-checkbox
                  id="${key}"
                  class="all-checkbox"
                  @change=${this._allFeaturesChanged(tenants, key)}
                  .checked=${this._isAllChecked(tenants, key)}>
                </d2l-input-checkbox>
              </th>
            `)}
          </tr>
          </thead>
          <tbody>
            ${repeat(tenants, tenant => tenant.id, tenant => html`
              <tr>
                <td>${tenant.name}</td>
                ${repeat(featureKeys, key => key, key => html`
                  <td class="checkbox-col">
                    <d2l-input-checkbox
                      id="${tenant.id}_${key}"
                      class="tenant-feature-checkbox"
                      @change=${this._featureCheckboxChanged(tenant, key)}
                      .checked=${tenant.hasFeature(key)}>
                    </d2l-input-checkbox>
                  </td>
                  `)}
              </tr>
            `)}
          </tbody>
        </table>
      </d2l-table-wrapper>
    `;

  }

  _isAllChecked(tenants, key) {
    return tenants.every(t => t.hasFeature(key));
  }

  async _saveFeatures() {
    try {
      await this.client.saveFeatures(this._updatedFeatures);
      this.session.toast({ type: 'default', message: 'Successfully saved the features' });
    } catch (err) {
      this.session.toast({ type: 'critical', message: 'There was an error saving the features' });
    }
  }

}

window.customElements.define('features-grid', FeaturesGrid);

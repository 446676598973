import { css, html, LitElement } from 'lit';

class NovaSeparator extends LitElement {
  static get styles() {
    return css`
      div {
        border-left: 1px solid var(--d2l-color-mica);
        box-sizing: border-box;
        display: inline;
        margin-right: 3px;
        vertical-align: middle;
      }
    `;
  }

  render() {
    return html`<div></div>`;
  }
}

customElements.define('nova-separator', NovaSeparator);

import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/inputs/input-search.js';
import { css, html, LitElement, nothing } from 'lit';
import { bodySmallStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import '../../../../shared/components/skills/skill-chip-list/skill-chip-list.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

export default class ActivitySkillTagger extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      // required -- modeled activity object
      activity: { type: Object },

      // optional - default 20 - number of selectable options shown after searching
      limit: { type: Number },

      // hide extracted skills from activity tag list (not from search list)
      hideExtracted: { type: Boolean, reflect: true, attribute: 'hide-extracted' },

      // whether skeleton is enabled or not
      skeleton: { type: Boolean, reflect: true },

      // fixed width of inputs and label (skill tags will not be fixed width)
      fixedWidth: { type: Boolean, reflect: true, attribute: 'fixed-width' },

      // suggestions that appear when searching
      _skillSuggestions: { type: Array },

      // true while searching in progress
      _searching: { state: true },

      // selected item (for aria-selected and aria-activedescendant)
      _selectedItemIndex: { type: Number },
    };
  }

  constructor() {
    super();
    this.hideExtracted = false;
    this.skeleton = false;
    this.fixedWidth = false;
    this.limit = 20;
    this._searching = false;
    this._skillSuggestions = [];
    this._selectedItemIndex = undefined;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
  }

  static get styles() {
    return [
      super.styles,
      bodySmallStyles,
      inputLabelStyles,
      css`
        :host {
          display: block;
        }
        #skill-search {
          max-width: 100%;
        }
        .fixed-width #skill-search {
          width: 400px;
        }
        skill-chip-list {
          display: block;
          margin-top: 12px;
        }
        .suggestions-wrapper {
          border: 1px solid var(--d2l-input-border-color, var(--d2l-color-galena));
          border-radius: 0.3rem;
          box-sizing: border-box;
          height: 180px;
          margin: 6px 0 12px;
          max-width: 100%;
          padding: 12px 0;
        }
        .suggestions-wrapper.empty {
          padding: 0.4rem 0.7rem;
        }
        .suggestions-wrapper.empty > p {
          margin: 0;
        }
        .fixed-width .suggestions-wrapper {
          width: 400px;
        }
        .suggestions-list-item {
          cursor: pointer;
          margin-right: 0.1rem;
          outline: none;
          overflow-x: hidden;
          padding: 0.4rem 0.7rem;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .suggestions-list-item[aria-selected="true"],
        .suggestions-list-item:hover,
        .suggestions-list-item:focus {
          background-color: var(--d2l-color-celestine-plus-2);
        }
        .suggestions-list-item .highlighted {
          font-weight: bold;
        }
        .suggestions-list {
          box-sizing: border-box;
          font-size: 0.8rem;
          font-weight: 400;
          height: 100%;
          letter-spacing: 0.02rem;
          line-height: 1.2rem;
          margin: 0;
          max-width: 100%;
          overflow: auto;
          padding: 0;
          vertical-align: middle;
        }
        .fixed-width .d2l-input-label {
          width: 400px;
        }
`,
    ];
  }

  get _fixedWidthClass() {
    return this.fixedWidth ? 'fixed-width' : undefined;
  }

  render() {
    if (!this.activity) return nothing;

    return html`
      <div class="${ifDefined(this._fixedWidthClass)}">
        ${this._skillPickerTemplate}
        <skill-chip-list
          class="d2l-skeletize"
          .skills=${this._skillsToDisplay}
          removable
          @nova-chip-remove=${this._removeSkill}>
        </skill-chip-list>
      </div>
    `;
  }

  get _skillsToDisplay() {
    const isDisplayed = skill => {
      if (skill.extracted && this.hideExtracted) return false;
      if (skill.omittedFromExtraction) return false;
      return true;
    };
    return this.activity.skills?.filter(skill => isDisplayed(skill)) || [];
  }

  get _skillPickerTemplate() {
    return html`
      ${this._searchInputTemplate}
      ${this._listTemplate}
    `;
  }

  _handleSuggestionsKeyUp(e) {
    const key = e.key;

    const moveUp = () => {
      const canMove = this._selectedItemIndex > 0;
      if (this._selectedItemIndex === undefined) {
        this._selectedItemIndex = this._skillSuggestions.length - 1;
      } else if (canMove) {
        this._selectedItemIndex--;
      }
    };

    const moveDown = () => {
      const canMove = this._selectedItemIndex < this._skillSuggestions.length - 1;
      if (this._selectedItemIndex === undefined) {
        this._selectedItemIndex = 0;
      } else if (canMove) {
        this._selectedItemIndex++;
      }
    };

    const selectNextItem = () => {
      this._selectSkillByIndex(this._selectedItemIndex);
      if (this._selectedItemIndex > this._skillSuggestions.length - 1) {
        this._selectedItemIndex = this._skillSuggestions.length - 1;
      }
      if (this._selectedItemIndex < 0) {
        this._selectedItemIndex = undefined;
      }
    };

    const elementNotVisible = element => {
      const childRect = element.getBoundingClientRect();
      const parentRect = element.parentElement.getBoundingClientRect();
      return childRect.bottom <= parentRect.top || childRect.top >= parentRect.bottom;
    };

    const scrollIntoView = () => {
      if (this._selectedItemIndex !== undefined) {
        const id = this._skillSuggestions[this._selectedItemIndex].id;
        const selectedItem = this.shadowRoot.querySelector(`#${id}`);
        if (elementNotVisible(selectedItem)) {
          const list = this.shadowRoot.querySelector('.suggestions-list');
          list.scrollTop = selectedItem.offsetTop - list.offsetTop;
        }
      }
    };

    switch (key) {
      case 'ArrowUp':
        moveUp();
        break;

      case 'ArrowDown':
        moveDown();
        break;

      case ' ':
      case 'Enter':
        selectNextItem();
        break;

      default:
        break;
    }
    scrollIntoView();
  }

  _handleSuggestionsKeyDown(e) {
    // prevent page from scrolling up or down
    // when skill picker is being used
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  }

  // Adapted from d2l-labs-autocomplete component...
  // This will make the text bold where it matches search value
  _computeText = (text, type) => {
    const inputText = this._skillSearchInput?.value;
    const indexOfFilter = text.toLowerCase().indexOf(inputText.toLowerCase());
    const filterInText = indexOfFilter !== -1;
    const indexOfFilterEnd = indexOfFilter + inputText.length;

    switch (type) {
      case 'prefix':
        return filterInText
          ? text.slice(0, indexOfFilter)
          : text;
      case 'bolded':
        return filterInText
          ? text.slice(indexOfFilter, indexOfFilterEnd)
          : '';
      case 'suffix':
        return filterInText
          ? text.slice(indexOfFilterEnd)
          : '';
    }
  };

  _skillToListItem = (skill, index) => {
    const isSelected = this._selectedItemIndex === index;
    return html`
      <li
        role="option"
        class="suggestions-list-item"
        @click=${this._skillSelected}
        id="${skill.id}"
        aria-label="${skill.name}"
        aria-selected="${isSelected}">
          ${this._computeText(skill.name, 'prefix')}<span class="highlighted">${this._computeText(skill.name, 'bolded')}</span>${this._computeText(skill.name, 'suffix')}
      </li>
    `;
  };

  get _searchHasInput() {
    return this._skillSearchInput?.value?.trim().length > 0;
  }

  // If no suggestions, show text
  // If suggestions exist, show the suggestions
  get _listTemplate() {
    if (this._skillSuggestions.length === 0) {
      let textToShow = this.localize('activity-skill-tagger.resultsPlaceholder');
      if (this._searching && this._searchHasInput) {
        textToShow = this.localize('activity-skill-tagger.searching');
      } else if (this._searchHasInput) {
        textToShow = this.localize('activity-skill-tagger.noResults');
      }
      return html`
        <div class="suggestions-wrapper empty d2l-skeletize">
          <p class="d2l-body-small">${textToShow}</p>
        </div>
      `;
    }

    const activeDescendant = this._selectedItemIndex
      ? this._skillSuggestions[this._selectedItemIndex]
      : nothing;
    return html`
      <div
        class="suggestions-wrapper d2l-skeletize"
        role="listbox"
        tabindex="0"
        @keyup=${this._handleSuggestionsKeyUp}
        @keydown=${this._handleSuggestionsKeyDown}>
        <ul
          class="suggestions-list d2l-body-small"
          aria-live="polite"
          aria-activedescendant="${activeDescendant}">
          ${this._skillSuggestions.map(this._skillToListItem)}
        </ul>
      </div>
    `;
  }

  get _searchInputTemplate() {
    return html`
      <label class="d2l-input-label d2l-skeletize" for="skill-search">${this.localize('activity-skill-tagger.mainLabel')}</label>
      <d2l-input-search
        id="skill-search"
        class="d2l-skeletize"
        label="${this.localize('activity-skill-tagger.mainLabel')}"
        search-on-input
        @d2l-input-search-searched=${this._handleSkillSearch}>
      </d2l-input-search>
    `;
  }

  _handleSkillSearch(e) {
    const text = e.currentTarget.value;
    this._debouncedSkillSearch(text);
  }

  get _skillSearchInput() {
    return this.shadowRoot.querySelector('#skill-search') ?? undefined;
  }

  _removeSkill(e) {
    const removedSkill = e.detail.skill;
    this.activity.removeSkillViaTagging(removedSkill.id);
    if (this._searchHasInput) {
      this._debouncedSkillSearch(this._skillSearchInput.value.trim());
    }
    this.requestUpdate();
    this._dispatchSkillsChangedEvent();
  }

  _skillSelected(e) {
    const skillId = e.currentTarget.id;
    const index = this._findSkillSuggestionIndex(skillId);
    this._selectSkillByIndex(index);
  }

  _selectSkillByIndex(skillIndex) {
    const selectedSkill = this._skillSuggestions.splice(skillIndex, 1)[0];
    const activitySkill = this._queriedSkillToActivitySkill(selectedSkill);
    this.activity.addSkillByTagging(activitySkill);
    if (this._skillSuggestions.length === 0) {
      this._setSkillSuggestions(this._skillSearchInput?.value);
    }
    this._dispatchSkillsChangedEvent();
    this.requestUpdate();
  }

  _dispatchSkillsChangedEvent() {
    this.dispatchEvent(new CustomEvent('skills-changed', {
      bubbles: true,
      composed: true,
      detail: this.activity.skills,
    }));
  }

  _queriedSkillToActivitySkill(queriedSkill) {
    return {
      id: queriedSkill.id,
      name: queriedSkill.name,
      extracted: false,
      omittedFromExtraction: false,
    };
  }

  _findSkillSuggestionIndex(id) {
    return this._skillSuggestions.findIndex(skill => skill.id === id);
  }

  _setSkillSuggestions = async text => {
    this._searching = true;
    if (!text || text.trim().length < 1) {
      this._skillSuggestions = [];
      return;
    }

    const queryLimit = this.limit + (this.activity.skills?.length ?? 0);
    const foundSkills = await this.client.querySkillsByName(text, queryLimit);
    const nonActivitySkills = foundSkills.filter(foundSkill => {
      const activitySkill = this.activity.getSkill(foundSkill.id);
      if (!activitySkill) return true;
      if (activitySkill.omittedFromExtraction) return true;
      return false;
    });

    this._skillSuggestions = nonActivitySkills.slice(0, this.limit);
    if (this._skillSuggestions.length === 0) {
      this._selectedItemIndex = undefined;
    } else if (this._selectedItemIndex !== undefined) {
      this._selectedItemIndex = 0;
    }
    this._searching = false;
  };

  _debounce(callback, timeout) {
    let timeoutId;
    return (...args) => {
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => {
        callback(...args);
      }, timeout);
    };
  }

  _debouncedSkillSearch = this._debounce(this._setSkillSuggestions, 250);

  _skillSearch(e) {
    const text = e.currentTarget?.text;
    this._debouncedSkillSearch(text);
  }

}

window.customElements.define('activity-skill-tagger', ActivitySkillTagger);

import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/dialog/dialog.js';
import '../../../../shared/components/general/detail-list/detail-list.js';
import '../application-terms-checkboxes/application-terms-checkboxes.js';

import { bodyCompactStyles, heading2Styles, labelStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import Activity from '../../../../../shared/models/activity/activity.js';
import { getLocalizedDateIgnoreTimezone } from '../../../../../shared/helpers/dateTime.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import Tenant from '../../../../../shared/models/tenant/index.js';

import { ATTRIBUTE_DISPLAY_OPTIONS } from '../../../../../shared/models/attribute-display-options.js';
import attributeDefinitions from '../../../../../shared/models/nova-attribute-definitions.js';
import { TOS_VERSION } from '../../../../../shared/constants.js';

export default class ReviewApplicationOverview extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      activity: { type: Object, attribute: false },
      customCondition: { type: Object, attribute: false },
      employer: { type: Object, attribute: false },
      errors: { type: Object, attribute: false },
      params: { type: Object, attribute: false },
      provider: { type: Object, attribute: false },
      requestedApprover: { type: Object, attribute: false },
      skeleton: { type: Boolean, attribute: false },
      tax: { type: Object, attribute: false },
      _customAttributes: { state: true },
      _submitting: { state: true },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodyCompactStyles,
      heading2Styles,
      labelStyles,
      css`
        :host {
          display: block;
        }

        detail-list {
          --detail-list-spacing: 0.6rem;
        }

        #overview-title {
          margin-top: 0;
        }
`,
    ];
  }

  constructor() {
    super();
    this.activity = new Activity();
    this.customCondition = {};
    this.employer = new Tenant();
    this.provider = new Tenant();
    this._customAttributes = [];

    this._applyClicked = this._applyClicked.bind(this);
    this._backClicked = this._backClicked.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
    window.addEventListener('apply-clicked', this._applyClicked);
    window.addEventListener('back-clicked', this._backClicked);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('apply-clicked', this._applyClicked);
    window.removeEventListener('back-clicked', this._backClicked);
  }

  firstUpdated() {
    this._updateCAFields();
  }

  render() {
    return html`
      ${this._overviewTemplate}
      ${this._formTemplate}
    `;
  }

  get _formTemplate() {
    const employerName = this.employer.name ? this.employer.name : this.localize(`apply-activity.${this.employer.learnerTerminology}.employer`);
    const providerName = this.provider.name ? this.provider.name : this.localize('apply-activity.provider');

    return html`
        <application-terms-checkboxes
          .customCondition=${this.customCondition}
          employer-name=${employerName}
          provider-name=${providerName}
          language=${this.session.settings.language}>
        </application-terms-checkboxes>`;
  }

  get _submitOwnRequestOverview() {
    const { url, title, description, startDate, endDate, startDateType, institution } = this.activity;
    const localStartDate = getLocalizedDateIgnoreTimezone(startDate);
    const localEndDate = getLocalizedDateIgnoreTimezone(endDate);
    const dateRange = (startDateType === 'anytime') ? this.localize('activity.startDate.anytime') : `${localStartDate} - ${localEndDate}`;
    const translatedType = this.activity.getTranslatedValue('type');
    const content = [
      { key: this.localize('submit-own-request.request-overview.website'), value: url },
      { key: this.localize('submit-own-request.request-overview.title'), value: title },
      { key: this.localize('submit-own-request.request-overview.description'), value: description },
      { key: this.localize('general.provider'), value: institution },
      { key: this.localize('submit-own-request.request-overview.type'), value: translatedType },
      { key: this.localize('submit-own-request.request-overview.date'), value: dateRange },
    ];

    return html`
        <detail-list .data=${content} no-sep></detail-list>
        <br/>
    `;
  }

  _updateCAFields() {
    const context = {
      isTaxable: this.session.draftApplication.taxable,
      application: this.session.draftApplication,
      user: this.session.draftApplication.user,
      lang: this.session.user.getSetting('language'),
      tenantType: this.session.user.tenantType,
    };
    const personalDetailsFields = attributeDefinitions.joinCustomAttributesWithDefault(
      ATTRIBUTE_DISPLAY_OPTIONS.APPLICATION_REVIEW_OVERVIEW,
      [...this.session.tenant.customAttributes, ...(this.provider?.customAttributes || [])],
      context);

    const applicationFields = attributeDefinitions.joinCustomAttributes(
      ATTRIBUTE_DISPLAY_OPTIONS.APPLICATION_REVIEW_OVERVIEW,
      this.session.tenant?.applicationAttributes,
      this.provider?.applicationAttributes,
      context);
    this._customAttributes = [...personalDetailsFields, ...applicationFields];
  }

  get _overviewTemplate() {
    const content = this._customAttributes.map(field => ({
      key: field.displayName.getTerm(this.session.user.getSetting('language')),
      value: field.value,
    }));
    content.push({
      key: this.localize('review-application.overview.approverNames'),
      value: this.session.draftApplication.getTranslatedApprovers(this.employer, 'apply-activity.name'),
    });
    content.push({
      key: this.localize('apply-activity.motivation.title'),
      value: html`
        <b>${this.localize('apply-activity.reason.prompt')}</b>
        <br/>
        ${this.session.draftApplication.message}`,
    });

    return html`
    ${this._isSubmitOwnRequest ? this._submitOwnRequestOverview : nothing}
        <h2
          id="overview-title"
          class="d2l-body-compact d2l-skeletize d2l-heading-2">
          ${this.localize('view-activity.activityOverview.header')}
        </h2>
        <detail-list .data=${content} no-sep></detail-list>
    `;
  }

  async _applyClicked() {
    if (this._submitting) return false;
    if (this.session.user.tenantType !== 'employer') {
      this.session.toast({ message: this.localize('apply-activity.error.cantApply') });
      return;
    }

    try {
      this._submitting = true;
      // Saves updated attributes on the user record
      await this.client.upsertUser(this.session.draftApplication.user);

      const app = await this._saveApplication();

      this.session.draftApplication = undefined;

      if (this._isSubmitOwnRequest) {
        this.client.logEvent({
          eventType: 'submitYourOwnRequestSubmitted',
          type: this.session.submitOwnRequest.activity.type,
        });

        // Clear smo session in case we want to submit another in the same session
        this.session.submitOwnRequest = undefined;
      }
      this.navigate(`/requests/${app.uuid}`);
    } finally {
      this._submitting = false;
    }
  }

  async _backClicked() {
    if (this.activity.admissionRequirementsLength > 0) {
      this.navigate(`/activities/${this.params.original_id}/${this.params.selected_id}/apply/review-requirements`);
    } else if (this._isSubmitOwnRequest) {
      this.navigate('/requests/submit-own-request');
    } else {
      this.navigate(`/activities/${this.params.original_id}/apply`);
    }
  }

  async _saveApplication() {
    if (this._isSubmitOwnRequest) {
      this.session.submitOwnRequest.requestedApprover = this.requestedApprover.id;

      return await this.client.applyExternalActivity(
        this.session.submitOwnRequest
      );
    }

    return await this.client.applyActivity(
      this.activity.id,
      this.requestedApprover.id,
      this.session.draftApplication.message,
      this.session.draftApplication.tax,
      TOS_VERSION,
      this.session.draftApplication.extraAttributes,
      this.session.draftApplication.location
    );
  }

  get _isSubmitOwnRequest() {
    return this.activity.hasTag('submitMyOwn');
  }

}

window.customElements.define('application-review-overview', ReviewApplicationOverview);

import { css } from 'lit';

export const novaTableStyles = css`
  .nova-table {
    display: grid;
    grid-row-gap: 8px;
  }

  .table-header {
    color: var(--d2l-color-celestine);
    display: grid;
    font-weight: bold;
    grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr 1fr;
    padding: 0 20px;
  }

  .table-row, .table-row-empty {
    align-items: center;
    border: 1px solid var(--d2l-color-mica);
    border-radius: 10px;
    display: grid;
    margin: auto 0;
    padding: 20px;
  }

  .table-row {
    cursor: pointer;
    grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr 1fr;
  }

  .table-row:hover {
    background-color: var(--d2l-color-regolith);
  }

  .text-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 10px;
    margin-right: 10px;
  }

  .main-text,
  .sub-text {
    flex: 0 1 auto;
    line-height: 1.6;
    margin: 0;
    max-width: fit-content;
    min-width: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .sub-text {
    color: var(--d2l-color-tungsten);
    font-size: 14px;
  }

  .approved-check {
    color: green;
    padding-left: 5px;
    width: 15px;
  }

  .approved-check-align-text {
    color: green;
    vertical-align: text-bottom !important;
    width: 15px;
  }

  @media (max-width: 767px) {
    .table-header {
      color: var(--d2l-color-celestine);
      display: grid;
      font-weight: bold;
      grid-template-columns: 1fr 2fr 1fr;
      padding: 0 20px;
    }

    .table-row {
      align-items: center;
      border: 2px solid var(--d2l-color-mica);
      border-radius: 10px;
      cursor: pointer;
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      margin: auto 0;
      padding: 15px;
    }

    .small-text {
      display: none;
    }
  }
`;

import { css, html, LitElement, nothing } from 'lit';

import '@brightspace-ui/core/components/alert/alert.js';

class ErrorList extends LitElement {
  static get properties() {
    return {
      errors: { type: Array },
    };
  }

  static get styles() {
    return [
      css`
        :host {
          display: block;
          margin-bottom: 24px;
        }
`,
    ];
  }

  constructor() {
    super();
    this.errors = [];
  }

  render() {
    if (!this.errors) return nothing;
    return html`
      <d2l-alert type="critical">
        ${this.errors.map(error => html`${error}`)}
      </d2l-alert>
    `;
  }
}
customElements.define('nova-error-list', ErrorList);

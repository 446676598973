import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/dropdown/dropdown-button.js';
import '@brightspace-ui/core/components/dropdown/dropdown-menu.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/inputs/input-textarea.js';
import '@brightspace-ui/core/components/menu/menu.js';
import '@brightspace-ui/core/components/menu/menu-item.js';
import '@brightspace-ui/core/components/tabs/tabs.js';
import '@brightspace-ui/core/components/tabs/tab-panel.js';
import { FormElementMixin } from '@brightspace-ui/core/components/form/form-element-mixin.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';
import { tableStyles } from '@brightspace-ui/core/components/table/table-wrapper.js';

import { css, html, LitElement, nothing } from 'lit';
import isEqual from 'lodash.isequal';
import { isJsonString } from '../../../../../shared/methods.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

const SUPPORTED_TYPES = ['string', 'number', 'array', 'boolean', 'object', 'null'];
const ROOT_KEY = 'nij_root';
const KEY_PATH_DELIMITER = '__';

class NovaInputJson extends LocalizeNova(FormElementMixin(LitElement)) {

  static get properties() {
    return {
      // Provide either a single object, or an array of objects. One of these is required.
      // REQUIRED
      object: { type: Object },

      // An instance of a nova-model-schema to validate against (must extend nova-model-schema class)
      novaModelSchema: { type: Object },

      // Don't display any keys with these names
      hiddenKeys: { type: Array, attribute: 'hidden-keys' },

      // Will hide JSON view, including both tabs on top
      formViewOnly: { type: Boolean, attribute: 'form-view-only' },

      // internal value, can retrieve with get value() method
      _rootObject: { type: Object },

      // internal property to track state of valid/invalid JSON in JSON view
      _invalidJsonInJsonView: { state: true },
    };
  }

  static get styles() {
    return [
      inputLabelStyles,
      selectStyles,
      tableStyles,
      css`
        :host {
          display: block;
        }

        .add-element-button,
        .add-field-button {
          width: 100%;
        }

        .array-item {
          margin-left: 19px;
        }

        .button-container {
          display: flex;
          gap: 12px;
          margin-top: 12px;
        }

        td.delete-cell {
          text-align: center;
        }

        .select-wrapper {
          width: 100%;
        }

        .select-wrapper > * {
          width: 100%;
        }

        .raw-json {
          min-height: 500px;
          width: 100%;
        }
`,
    ];
  }

  updated() {
    if (this._rootObject) return; // exit because only need to do this to initialize the value
    this._rootObject = this.object;
    this._initialRootObject = this.object;
  }

  validate() {
    if (this.novaModelSchema) {
      this._validateAgainstNovaModelSchema(this._rootObject);
    }
  }

  get value() {
    return this._rootObject;
  }

  get valueHasChanged() {
    return isEqual(this._rootObject, this._initialRootObject);
  }

  get isValidPerNovaModelSchema() {
    return this._isValidPerNovaModelSchema;
  }

  get hasInvalidJsonInJsonView() {
    return this._invalidJsonInJsonView;
  }

  _booleanSelector(key, value, keyPath) {
    return html`
      <div class="select-wrapper">
        <select class="d2l-input-select" data-key=${key} value="${value}" data-key-path="${keyPath}">
          <option value="false" ?selected=${value === false}>false</option>
          <option value="true" ?selected=${value === true}>true</option>
        </select>
      </div>
    `;
  }

  get _buttonContainer() {
    return html`
      <div class="button-container">
        ${this._addFieldButton(ROOT_KEY, ROOT_KEY, SUPPORTED_TYPES)}
      </div>
    `;
  }

  _deleteFieldButton(key, keyPath) {
    return html`
      <d2l-button-icon
        class="delete-kv-pair-button"
        text=${this.localize('nova-json-input.deleteField.buttonText')}
        data-key=${key}
        data-key-path=${keyPath}
        icon="tier1:delete"
        @click=${this._onDeleteItem}>
      </d2l-button-icon>
    `;
  }

  _nullField() {
    return 'Null';
  }

  _inputField(key, value, path, keyOrValue) {
    const type = typeof value;

    // type === object includes arrays and also null
    if (type === 'object') {
      if (value === null) {
        return this._nullField();
      } else if (Array.isArray(value)) {
        return this._addElementButton(key, path);
      } else {
        return this._addFieldButton(key, path);
      }
    }

    if (type === 'string' || type === 'number') {
      return this._d2lInputText(type, key, value, path, keyOrValue);
    }

    if (type === 'boolean') {
      return this._booleanSelector(key, value);
    }

    console.error('nova-json-input: Invalid type selected');
  }

  _addFieldButton(key, keyPath, supportedTypes = SUPPORTED_TYPES) {
    const selectionHandler = this._handleAddField;
    return html`
      <d2l-dropdown-button text=${this.localize('nova-json-input.addField.buttonText')} class="add-field-button">
        ${this._itemMenu(key, keyPath, supportedTypes, selectionHandler)}
      </d2l-dropdown-button>
    `;
  }

  _addElementButton(key, keyPath, supportedTypes = SUPPORTED_TYPES) {
    const selectionHandler = this._handleAddElement;
    return html`
      <d2l-dropdown-button text=${this.localize('nova-json-input.addElement.buttonText')} class="add-element-button">
        ${this._itemMenu(key, keyPath, supportedTypes, selectionHandler)}
      </d2l-dropdown-button>
    `;
  }

  _itemMenu(key, keyPath, supportedTypes, selectionHandler) {
    return html`
      <d2l-dropdown-menu>
        <d2l-menu label=${this.localize('nova-json-input.addField.menuLabel')}>
          ${supportedTypes.map(type => html`
            <d2l-menu-item
              @d2l-menu-item-select=${selectionHandler}
              text="${this._capitalize(type)}"
              data-type="${type}"
              data-key="${key}"
              data-key-path="${keyPath}">
            </d2l-menu-item>
          `)}
        </d2l-menu>
      </d2l-dropdown-menu>
    `;
  }

  _getDefaultValueByType(type) {
    switch (type) {
      case 'string':
        return '';

      case 'number':
        return 0;

      case 'array':
        return [];

      case 'object':
        return {};

      case 'boolean':
        return false;

      case 'null':
      default:
        return null;
    }
  }

  _capitalize(str) {
    return (str.charAt(0).toUpperCase() + str.slice(1));
  }

  _d2lInputText(type, key, value, path, keyOrValue) {
    const typeMap = {
      string: 'text',
      number: 'number',
    };
    return html`
      <d2l-input-text
        class="${keyOrValue}"
        type="${typeMap[type] || 'text'}"
        label=${this.localize('nova-json-input.value.label', { key })}
        label-hidden
        data-key="${key}"
        data-key-path="${path}"
        .value=${value}
        style="min-width: 200px"
        @change=${this._onInputChange}
      ></d2l-input-text>
    `;
  }

  _findParentAndKeyByKeyPath(obj, keyPath) {
    const keys = keyPath.split(KEY_PATH_DELIMITER);
    if (keys[0] === ROOT_KEY) {
      keys.splice(0, 1);
    }

    let currentParent = obj;
    for (let i = 0; i < keys.length - 1; i++) {
      currentParent = currentParent[keys[i]];
    }
    return { parentObject: currentParent, key: keys[keys.length - 1] };
  }

  _deleteKeyByKeyPath(keyPath) {
    const { parentObject, key } = this._findParentAndKeyByKeyPath(this._rootObject, keyPath);
    delete parentObject[key];
  }

  _updateValueByKeyPath(keyPath, newValue) {
    const { parentObject, key } = this._findParentAndKeyByKeyPath(this._rootObject, keyPath);
    parentObject[key] = newValue;
    this._fireChangedEvent();
  }

  _updateKeyByKeyPath(keyPath, newKey) {
    const { parentObject, key } = this._findParentAndKeyByKeyPath(this._rootObject, keyPath);
    const value = parentObject[key];
    delete parentObject[key];
    parentObject[newKey] = value;
    this._fireChangedEvent();
  }

  _updateRootObjectFromJsonString(jsonString) {
    this._rootObject = JSON.parse(jsonString);
  }

  _onDeleteItem(e) {
    const path = e.currentTarget.getAttribute('data-key-path');
    this._deleteKeyByKeyPath(path);
    this.update();
    this._fireChangedEvent();
  }

  _onInputChange(e) {
    const path = e.target.getAttribute('data-key-path');
    const inputType = e.target.type;
    let value = e.target.value;
    if (inputType === 'number') value = parseFloat(value);

    // Check if the raget has the class 'key'
    if (e.target.classList.contains('key')) {
      this._updateKeyByKeyPath(path, value);
    } else {
      this._updateValueByKeyPath(path, value);
    }
    this.validate();
    this.update();
    this._fireChangedEvent();
  }

  _fireChangedEvent() {
    const jsonInputChanged = new CustomEvent('nova-json-input-changed', {
      bubbles: true,
      composed: true,
      detail: { value: this.value },
    });
    this.dispatchEvent(jsonInputChanged);
  }

  _validateAgainstNovaModelSchema() {
    if (!this.novaModelSchema.isValid(this._rootObject)) {
      this._isValidPerNovaModelSchema = false;
    } else {
      this._isValidPerNovaModelSchema = true;
    }
  }

  _handleAddField(e) {
    const target = e.currentTarget;
    const type = target.getAttribute('data-type');
    const keyPath = target.getAttribute('data-key-path');
    this._addNewField(keyPath, type);
  }

  _addNewField(keyPath, type) {
    const value = this._getDefaultValueByType(type);
    const newObject = { [this._newKeyName]: value };
    if (keyPath === ROOT_KEY) {
      Object.assign(this._rootObject, newObject);
    } else {
      const { parentObject, key } = this._findParentAndKeyByKeyPath(this._rootObject, keyPath);
      Object.assign(parentObject[key], newObject);
    }
    this.update();
    this._fireChangedEvent();
  }

  _handleAddElement(e) {
    const target = e.currentTarget;
    const type = target.getAttribute('data-type');
    const keyPath = target.getAttribute('data-key-path');
    this._addNewElement(keyPath, type);
  }

  _addNewElement(keyPath, type) {
    const newElement = this._getDefaultValueByType(type);
    const { parentObject, key } = this._findParentAndKeyByKeyPath(this._rootObject, keyPath);
    parentObject[key].push(newElement);
    this.update();
    this._fireChangedEvent();
  }

  get _newKeyName() {
    this.keyCounter === undefined ? this.keyCounter = 1 : this.keyCounter++;
    return `new-key-${this.keyCounter}`;
  }

  get _tableHeader() {
    return html`
      <thead>
        <tr>
          <th>${this.localize('nova-json-input.headers.key')}</th>
          <th>${this.localize('nova-json-input.headers.value')}</th>
          <th>${this.localize('nova-json-input.headers.type')}</th>
          <th>${this.localize('nova-json-input.headers.remove')}</th>
        </tr>
      </thead>
    `;
  }

  get _tableTemplate() {
    return html`
      <d2l-table-wrapper>
        <table class="d2l-table">
          ${this.hideHeader ? nothing : this._tableHeader}
          <tbody>
            ${this._jsonRows()}
          </tbody>
        </table>
      </d2l-table-wrapper>
    `;
  }

  _jsonRows(jsonObject = null, nesting = 0, path = ROOT_KEY, parentType) {
    if (jsonObject === null) {
      jsonObject = this._rootObject;
    }

    if (typeof jsonObject !== 'object') {
      console.error('nova-json-input: input must be an object or an array');
      return;
    }

    return Object.keys(jsonObject).map((key, index) => {

      if (this.hiddenKeys?.indexOf(key) >= 0) {
        return nothing;
      }

      const value = jsonObject[key];
      const isArray = Array.isArray(value);
      let valueType = typeof value;
      const keyPath = `${path}__${key}`;

      let additionalRows = nothing;
      if (value === null) {
        valueType = 'null';
      } else if (typeof value === 'object') {
        if (isArray) valueType = 'array';
        additionalRows = this._jsonRows(value, nesting + 1, keyPath, valueType);
      }

      const keyHtml = parentType === 'array'
        ? html`<span class="array-item">${this.localize('nova-json-input.arrayIndex', { index })}</span>`
        : html`<span>${this._inputField(key, key, keyPath, 'key')}</span>`;

      return html`
        <tr>
          <td style="overflow: visible; padding-left: calc(1rem + ${ nesting * 19 }px">
            ${keyHtml}
          </td>
          <td>${this._inputField(key, value, keyPath, 'value')}</td>
          <td>${this._capitalize(valueType)}</td>
          <td class="delete-cell">${this._deleteFieldButton(key, keyPath)}</td>
        </tr>
        ${additionalRows}
      `;
    });
  }

  get _mainInputEditor() {
    if (this._invalidJsonInJsonView) {
      return html`<p>${this.localize('nova-json-input.rawJsonPreview.invalid')}</p>`;
    }

    return html`
      ${this._tableTemplate}
      ${this._buttonContainer}
    `;
  }

  get _tabbedEditor() {
    return html`
      <d2l-tabs>
        <d2l-tab-panel selected text=${this.localize('nova-json-input.mainEditor.label')}>
          ${this._mainInputEditor}
        </d2l-tab-panel>
        <d2l-tab-panel text=${this.localize('nova-json-input.rawJsonPreview.label')}>
          <d2l-input-textarea
            label=${this.localize('nova-json-input.rawJsonPreview.label')}
            class="raw-json"
            max-rows=50
            @change=${this._handleRawJsonInput}
            value=${JSON.stringify(this._rootObject, null, 2)}
            aria-invalid=${this._invalidJsonInJsonView ?? 'false'}>
          </d2l-input-textarea>
        </d2l-tab-panel>
      </d2l-tabs>
    `;
  }

  _handleRawJsonInput(e) {
    const value = e.currentTarget.value;
    this._validateJsonString(value);
    if (!this._invalidJsonInJsonView) {
      this.validate();
      this._updateRootObjectFromJsonString(value);
    }
  }

  // This validates that the JSON is just plain valid, per JSON.parse rules.
  // Doesn't check any schema or anything like that.
  _validateJsonString(value) {
    this._invalidJsonInJsonView = !isJsonString(value);
  }

  render() {
    if (!this.object) return 'No JSON object provided as value';
    if (!this._rootObject) return nothing;
    return this.formViewOnly ? this._mainInputEditor : this._tabbedEditor;
  }

}

window.customElements.define('nova-json-input', NovaInputJson);
